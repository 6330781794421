import './App.css';
import React, {useEffect, useRef, useState} from 'react';

// Images
import darkLogo from './images/portal_dark.png';
import logo from './images/Portal_Logo_Main.png';
import darkRingLogo from './images/Portal_Ring_Logo.png';
import smallRingLogo from './images/Portal_Ring_Logo_Small.png';
import cross from './images/cross.png';
import check from './images/check.png';
import smallPoint from './images/Guy_Pointing_Small.png';
import checklist from './images/checklist.png';
import dottedCircle from './images/dotted_circle.png';
import message from './images/message.png';
import feat0 from './images/Feature_0.webp';
import feat1 from './images/Feature_1.webp';
import feat2 from './images/Feature_2.webp';

// Components
import EarlyAccessForm from './components/EarlyAccessForm';
import ContactForm from './components/ContactForm';
import CareerCard from './components/CareerCard';
import FeaturesTab from './components/FeaturesTab';
import FeaturesCarousel from './components/FeaturesCarousel';

// Other Components
import ReactGA from 'react-ga4';

// Bootstrap (External)
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Stack, Offcanvas } from 'react-bootstrap';

function App() {
  // Navigation References
  const wwdRef = useRef(null)
  const featRef = useRef(null)
  const ccRef = useRef(null)
  const connectRef = useRef(null)
  const faqRef = useRef(null)

  // Showing Modals
  const [earlyAccessModalShow, setEarlyAccessModalShow] = useState(false);
  const [contactModalShow, setContactModalShow] = useState(false);
  const handleShowEarlyAccess = () => {
    ReactGA.event({
      category: 'button',
      action: 'get-early-access',
      label: 'pressed-gea-form'
    }) 
    setEarlyAccessModalShow(true);
  }
  const handleCloseEarlyAccess = () => {
    setEarlyAccessModalShow(false);
  }
  const handleShowContactForm = () => {
    ReactGA.event({
      category: 'button',
      action: 'get-contact-us',
      label: 'pressed-contact-form'
    }) 
    setContactModalShow(true);
  }
  const handleCloseContactForm = () => setContactModalShow(false);

  // Navigation OffCanvas
  const [showOffCanvas, setOffCanvas] = useState(false)
  const handleShowOffCanvas = () => {setOffCanvas(true)}
  const handleCloseOffCanvas = () => {setOffCanvas(false)}

  const Divider = () => {
    return (
      <hr style={{ borderTop: '2px solid black'}}/>
    )
  }
  const DarkDivider = () => {
    return (
      <hr style={{ borderTop: '2px solid gray'}}/>
    )
  }
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App">
      <EarlyAccessForm
        show={earlyAccessModalShow}
        onHide={() => handleCloseEarlyAccess()}
        submitprocess={handleCloseEarlyAccess}
      />
      <ContactForm
        show={contactModalShow}
        onHide={() => handleCloseContactForm()}
        submitprocess={handleCloseContactForm}
      />
      <Stack className='header' direction='horizontal'>
        { windowDimensions.width < 768 && <>
          <div><img src={darkLogo} alt='logo'/></div>
          <div className='ms-auto headerMobileBtn'>
            <button onClick={handleShowOffCanvas}>☰</button>
          </div>
          </>
        }
        {windowDimensions.width >= 768 &&
          <div style={{display:'flex', width:'100%'}}>
            <div style={{display:'block',margin:'auto'}}>
              <img src={darkLogo} alt='logo'/>
            </div>
            <div style={{display:'block',margin:'auto'}}>
            <button
              onClick={() => {  
              ReactGA.event({
                category: 'navbar',
                action: 'nav',
                label: 'What We Do - Nav Bar'
              }) 
              wwdRef.current.scrollIntoView({behavior: 'smooth'})}
            }> 
              What We Do 
            </button>
            <button onClick={() => {
              ReactGA.event({
                category: 'navbar',
                action: 'nav',
                label: 'Features - Nav Bar'
              })
              featRef.current.scrollIntoView({behavior: 'smooth'})}
            }> Features </button>
            <button onClick={() => {
              ReactGA.event({
                category: 'navbar',
                action: 'nav',
                label: 'Career Center - Nav Bar'
              })
              ccRef.current.scrollIntoView({behavior: 'smooth'})}
            }> Career Centers </button>
            <button onClick={() => {
              ReactGA.event({
                category: 'navbar',
                action: 'nav',
                label: 'Get Connected - Nav Bar'
              })
              connectRef.current.scrollIntoView({behavior: 'smooth'})}
            }> Contact Us </button>
            <button style={{marginRight:'15px'}} onClick={() => {
              ReactGA.event({
                category: 'navbar',
                action: 'nav',
                label: 'FAQ - Nav Bar'
              })
              faqRef.current.scrollIntoView({behavior: 'smooth'})}
            }> FAQ </button>
            </div>
          </div>
        }
      </Stack>
      <Stack className='wwd' ref={wwdRef}>
        <>
          <h1>
            Discover Your <br></br>
            Future Career.
          </h1>
          
            {/* {(windowDimensions.width >= 537 && windowDimensions.width <= 734) || (windowDimensions.width >= 388 && windowDimensions.width <= 480) ?
              <p>
                See inside the careers you are or should <br></br> 
                be considering through short-form videos from professionals.
              </p> :
              (windowDimensions.width >= 481 && windowDimensions.width <= 536) ?
              <p>
                See inside the careers you are or <br></br>should be considering
                through short-form videos from professionals.
              </p>
              :
              <p>
                See inside the careers you are or should be considering <br></br>
                through short-form videos from professionals.
              </p>
            } */}
            <p style={{marginLeft: windowDimensions.width >= 786 ? '27%' : '5%', marginRight: windowDimensions.width >= 786 ? '27%' : '5%'}}>
              Get inside looks into careers through short-form videos from professionals
            </p>
        </>
        <button className='gea-btn' style={{ fontSize: 26 }} onClick={() => {
          handleShowEarlyAccess();
          // handleShowContactForm();
        }}> Get Early Access </button>
        {windowDimensions.width < 768 ? <img src={smallRingLogo} alt='ring-logo'></img> : <img src={darkRingLogo} alt='ring-logo'></img>}
        {/* <img src={darkRingLogo} alt='ring-logo'></img> */}
      </Stack>
      {windowDimensions.width < 768 &&
      <Offcanvas 
        className='vh-100 mobileNavHeader' 
        placement='top' 
        show={showOffCanvas} 
        onHide={handleCloseOffCanvas}
        scroll={true}
        responsive="md"
      >
        <Offcanvas.Header>
          <button 
            className='ms-auto' 
            onClick={handleCloseOffCanvas}>
            <img src={cross} alt='cross'/>
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Stack className='navButtons'>
            <button onClick={() => {
              handleCloseOffCanvas()
              setTimeout(() => {
                wwdRef.current.scrollIntoView({behavior: 'smooth'});
              }, 300)
            }}>
              What We Do
            </button>
            <button onClick={() => {
              handleCloseOffCanvas()
              setTimeout(() => {
                featRef.current.scrollIntoView({behavior: 'smooth'});
              }, 300)
            }}>
              Features
            </button>
            <button onClick={() => {
              handleCloseOffCanvas()
              setTimeout(() => {
                ccRef.current.scrollIntoView({behavior: 'smooth'});
              }, 300)
            }}>Career Centers</button>
            <button onClick={() => {
              handleCloseOffCanvas()
              setTimeout(() => {
                faqRef.current.scrollIntoView({behavior: 'smooth'});
              }, 300)
            }}>
              FAQ
            </button>
            <button onClick={() => {
              handleCloseOffCanvas()
              setTimeout(() => {
                connectRef.current.scrollIntoView({behavior: 'smooth'});
              }, 300)
            }}>Contact Us</button>
            <button 
              className='gea-nav-btn'
              onClick={() => {
                handleCloseOffCanvas()
                setTimeout(() => {
                  handleShowEarlyAccess()
                }, 300)
              }}
            >Get Early Access</button>
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
      }
      <Stack className='features' ref={featRef}>
        {windowDimensions.width < 768 &&
        <h2> 
          Portal Helps You <br></br>
          Find A Career <br></br>
          You'll Love.
        </h2>
        }
        {windowDimensions.width >= 768 &&
        <h2> 
          Portal Helps You Find<br></br>
          A Career You'll Love.
        </h2>
        }
        {windowDimensions.width < 768 && <FeaturesCarousel/>}
        {windowDimensions.width >= 768 && <div style={{display:'block', margin:'auto'}}>
          <Stack direction='horizontal'>
          
          <FeaturesTab 
          header="Select your interests" 
          desc={
              <p>
                Choose what excites you and receive <br></br>
                personalized career video content tailored <br></br>  
                to your professional and academic <br></br>
                interests.
              </p>
          }
          headerImg={checklist}
          featureImg={feat0}
          featureImgClass={"featureIcon"}
          className={"featureTab0"}
        />
        <FeaturesTab 
          header="Enter the Portal" 
          desc={
            <p>
              See inside the careers you are or should be <br></br>
              considering through short-form videos <br></br>
              made by professionals on personalized, <br></br>
              customizable content feeds.
            </p>
          }
          headerImg={dottedCircle}
          featureImg={feat1}
          featureImgClass={"featureIcon"}
          className={"featureTab1"}
        />
        <FeaturesTab 
          header="Message Professionals" 
          desc={
            <p>
              Connect with the video creators to ask <br></br> 
              them questions about their role, make a  <br></br>
              professional connection, or set up one day <br></br>
              shadowing opportunities.
            </p>
          }
          headerImg={message}
          featureImg={feat2}
          featureImgClass={"featureIcon"}
          className={"featureTab2"}
        />
        </Stack>
        </div>}
      </Stack>

      <Stack 
        className='benefits' 
        direction={windowDimensions.width < 768 ? 'vertical' : 'horizontal'}
      >
        {windowDimensions.width < 768 &&
            <div style={{margin:'auto',display:'block'}}>
            <h2>
              Unlock The Same <br></br>
              Information You <br></br>
              Get From <br></br>
              Internships
            </h2>
            <p>
              Gain invaluable career insights such as <br></br>
              the day-to-day of professionals in your <br></br>
              fields of interests, their opinions about <br></br>
              the job, the basic knowledge required, <br></br>
              and much more without the internship <br></br>
              commitment.
            </p>
            <button className='gea-btn' onClick={() => {
            handleShowEarlyAccess();
          }}> Get Early Access</button>
            </div>
        }
        <div>
          {windowDimensions.width >= 768 && 
            <div style={{width:windowDimensions.width * 0.4}}>
              <h2>
                Unlock The Same <br></br>
                Information You Get <br></br>
                From Internships
              </h2>
              <p>
                Gain invaluable career insights such as <br></br>
                the day-to-day of professionals in your <br></br>
                fields of interests, their opinions about <br></br>
                the job, the basic knowledge required, <br></br>
                and much more without the internship <br></br>
                commitment.
              </p>
              <button className='benefitBtn' onClick={() => {
            handleShowEarlyAccess();
          }}> Get Early Access</button>
            </div>
          }
        </div>
        {windowDimensions.width < 768 ?
        <div style={{margin:'auto', display:'block'}}>
          <Stack direction='horizontal'>
            <img className='listBullet' src={check} alt='check'/>
            {windowDimensions.width < 768 &&
            <p className='list'>
              Witness typical <br></br>
              days-in-the-life of your <br></br>
              career interests
            </p>
            }
            {windowDimensions.width >= 768 &&
            <p className='list'>
              Witness typical days-in-the-life
              of your career interests
            </p>
            }
          </Stack>
          <Divider/>
          <Stack direction='horizontal'>
            <img className='listBullet' src={check} alt='check'/>
            {windowDimensions.width < 768 ? 
            <p className='list'>
            Hear testimonials from <br></br>
            professionals in those <br></br>
            fields
          </p> :
          <p className='list'>
          Hear testimonials from professionals in
          those fields
        </p>  
          }
          </Stack>
          <Divider/>
          <Stack direction='horizontal'>
              <img className='listBullet' src={check} alt='check'/>
              {windowDimensions.width < 768 ?
              <p className='list'>
              Learn the basic <br></br>
              knowledge required
            </p> :
            <p className='list'>
            Learn the basic knowledge required
          </p>
              }
          </Stack>
          <Divider/>
          <Stack direction='horizontal'>
              <img className='listBullet' src={check} alt='check'/>
              {windowDimensions.width < 768 ?
              <p className='list'>
              See example projects you <br></br>
              might work on
            </p> :
            <p className='list'>
            See example projects you might work on
          </p>
              }
          </Stack>
          <Divider/>
          <Stack direction='horizontal'>
              <img className='listBullet' src={check} alt='check'/>
              <p className='list'>
                Much more...
              </p>
          </Stack>
        </div> : 
        <Stack style={windowDimensions.width >= 768 ? {marginLeft: 30, display:'block', marginBottom:'auto'} : {}}>
          <Stack direction='horizontal'>
                <img className='listBullet' src={check} alt='check'/>
                {windowDimensions.width < 768 &&
                <p className='list'>
                  Witness typical <br></br>
                  days-in-the-life of your <br></br>
                  career interests
                </p>
                }
                {windowDimensions.width >= 768 &&
                  <p className='list witness'>
                  Witness typical days-in-the-life
                  of your career interests
                </p>
                }
          </Stack>
          <Divider/>
          <Stack direction='horizontal'>
              <img className='listBullet' src={check} alt='check'/>
              {windowDimensions.width < 768 ? 
              <p className='list'>
              Hear testimonials from <br></br>
              professionals in those <br></br>
              fields
            </p> :
            <p className='list hear-test'>
            Hear testimonials from professionals in
            those fields
          </p>  
            }
          </Stack>
          <Divider/>
          <Stack direction='horizontal'>
              <img className='listBullet' src={check} alt='check'/>
              {windowDimensions.width < 768 ?
              <p className='list'>
              Learn the basic <br></br>
              knowledge required
            </p> :
              <p className='list basic-know'>
              Learn the basic knowledge required
              </p>
              }
          </Stack>
          <Divider/>
          <Stack direction='horizontal'>
              <img className='listBullet' src={check} alt='check'/>
              {windowDimensions.width < 768 ?
              <p className='list'>
              See example projects you <br></br>
              might work on
            </p> :
            <p className='list ex-proj'>
            See example projects you might work on
          </p>
              }
          </Stack>
          <Divider/>
          <Stack direction='horizontal'>
              <img className='listBullet' src={check} alt='check'/>
              <p className='list much-more'>
                  Much more...
              </p>
          </Stack>
        </Stack>
        }
      </Stack>
      <div 
        style={{backgroundColor: 'black', display:'block', marginLeft:'auto', marginRight:'auto', marginTop:'6rem', marginBottom:'8rem'}} 
        className='wwdVideoBackground'
      >
          <video
              src={windowDimensions.width < 768 ? 'videos/Vertical_Captioned.mov' : 'videos/Horizontal_Captioned.mp4'}
              className='wwdVideo'
              controls={true}
          />
      </div>
      <Stack className='careerCenter' ref={ccRef}>
        <div style={windowDimensions.width < 768 ? {margin:'auto', display:'block'} : {marginBottom:0}}>
          {windowDimensions.width < 768 ?
            <h2>
              Get Help From <br></br>
              Your University's <br></br>
              Career Center <br></br>
              Asynchronously
            </h2>
            :
            <h2>
              Get Help From Your <br></br>
              University's Career Center <br></br>
              Asynchronously
            </h2>
          }
        </div>
        <Stack direction={windowDimensions.width < 768 ? 'vertical' : 'horizontal'} className='careerCenterContent'>
          <div style={{display: 'block', margin: 'auto'}} className='careerCenterLeftColumn'>
            {windowDimensions.width < 768 ?
            <p>
              Career centers can grant their <br></br>
              students a comprehensive way <br></br>
              to discover their future career, <br></br>
              increasing the center's impact <br></br>
              and reach among the student <br></br>
              body.
            </p> : 
            <p>
            Career centers can grant their students a <br></br>
            comprehensive way to discover their future career,<br></br>
            increasing the center's impact and reach among the<br></br>
            student body.
          </p>
            }
          <DarkDivider/>
          <Stack direction='horizontal'>
              <img className='listBullet' src={check} alt='check'/>
              {windowDimensions.width < 768 ?
              <p className='list'>
                Excite students about <br></br>
                their career possibilities
              </p> :
              <p className='list'>
              Excite students about their career possibilities
            </p>
              } 
          </Stack>
          <DarkDivider/>
          <Stack direction='horizontal'>
              <img className='listBullet' src={check} alt='check'/>
              { windowDimensions.width < 768 ?
              <p className='list'>
                Bring more students into <br></br>
                the career center
              </p> :
              <p className='list'>
                Bring more students into the career center
              </p>
              }
          </Stack>
          <DarkDivider/>
          <Stack direction='horizontal'>
              <img className='listBullet' src={check} alt='check'/>
              {windowDimensions.width < 768 ? 
              <p className='list'>
                Increase job satisfaction <br></br>
                among graduates
              </p> :
              <p className='list'>
              Increase job satisfaction among graduates
              </p>}
          </Stack>
          <DarkDivider/>
          <button 
            style={{marginTop: 20, marginBottom:50}}
            className={windowDimensions.width < 768 ? 'gea-btn' : 'careerBtn'} 
            onClick={() => {
              // handleShowEarlyAccess();
              handleShowContactForm();
            }}
          > Contact Us</button>
          </div>
        <Stack direction={windowDimensions.width < 768 ? 'horizontal' : 'vertical'}>
        <div style={windowDimensions.width >= 768 ? {display:'block', marginLeft:'auto', marginRight:'auto', marginTop:'auto'} : {display:'block', marginLeft:'auto', marginRight:'auto'}}>
        <img src={smallPoint} alt='point' className='point'/>
        </div>
        </Stack>
        </Stack>
      </Stack>

      <Stack 
        className='getConnected' 
        ref={connectRef} 
        direction={windowDimensions.width < 768 ? 'vertical' : 'horizontal'}
        gap={20}
      >
        <CareerCard
          bottom={windowDimensions.width < 768 ? 60 : 0}
          header={windowDimensions.width < 768 ? <h3>STUDENTS & JOB <br></br>SWITCHERS</h3> : <h3>STUDENTS & JOB SWITCHERS</h3>}
          body={windowDimensions.width < 768 ? <h2>Begin your <br></br> Journey to a <br></br> Happy Career</h2> : <h2>Begin your Journey to <br></br>a Happy Career</h2>}
          // btnLabel='Get Early Access'
          btnLabel='Get Early Access'
          onClick={() => {
            handleShowEarlyAccess();
          }}
          width={windowDimensions.width < 768 ? '' : windowDimensions.width * 0.4}
          right={windowDimensions.width < 768 ? 0 : '5%'}
        />
        <CareerCard
          bottom={windowDimensions.width < 768 ? 120 : 0}
          header={windowDimensions.width < 768 ? <h3>CREATORS & CAREER <br></br>CENTERS</h3> : <h3> CREATORS & CAREER CENTERS</h3>}
          body={windowDimensions.width < 768 ? <h2>Interested in <br></br> what we're <br></br> doing?</h2> : <h2>Interested in what <br></br> we're doing?</h2>}
          btnLabel='Contact Us'
          onClick={() => {
            handleShowContactForm();
          }}
          width={windowDimensions.width < 768 ? '' : windowDimensions.width * 0.4}
        />
      </Stack>

      <Stack className='faq' ref={faqRef}>
          {windowDimensions.width < 768 ?
            <h2 className='faqHeader'>
              Frequently Asked<br></br>
              Questions
            </h2> : 
            <h2 className='faqHeader'>
              Frequently <br></br>
              Asked Questions
            </h2>
          }
        <Divider/>
        <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span className='faqNumber'>01</span>
                <span className='faqQuestion'>Is this free to download?</span>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                    Portal is free to download and use for individual students and job seekers. 
                    The basic version of the app provides access to a wide range of career-related videos, 
                    allowing users to explore different industries, learn about specific roles, and gain insights 
                    from experienced professionals.
                </p>
                <p>
                    However, Portal also offers a premium version of the app designed specifically for university 
                    career centers. By partnering with Portal, career centers can provide their students with enhanced 
                    features and benefits, such as unlimited access to all career-related content, personalized 
                    recommendations, enhanced networking capabilities, and dedicated support from the Portal team.
                </p>
                <p>
                    Whether you access Portal through your university or download it independently, our platform is 
                    designed to empower you to make informed decisions about your career, figure out your professional 
                    goals, and achieve them.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Divider/>
        <Accordion>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <span className='faqNumber'>02</span>
                <span className='faqQuestion'>Are creators on the app verified?</span>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                    To ensure the integrity and credibility of our platform, all creators on Portal are 
                    verified before they can share their insights and experiences with our community. 
                    Our verification process involves a thorough review of each creator's professional 
                    background, expertise, and industry credentials. We work closely with our partners, 
                    including universities, alumni associations, and professional organizations, to identify 
                    and recruit qualified creators who can provide valuable perspectives and advice to our users. 
                    When you watch a video on Portal, you can trust that the creator has been vetted and approved 
                    by our team. We believe that this verification process is essential to maintaining the quality 
                    and trustworthiness of our content, and to ensuring that our users can make informed decisions 
                    about their careers based on reliable information from credible sources.
                </p>
                <p>
                    In addition to our verification process, we also encourage our users to provide feedback and 
                    report any content that they believe is inaccurate, inappropriate, or misleading. Our team 
                    regularly reviews user feedback and takes action to remove or correct any content that does 
                    not meet our standards of quality and integrity.
                </p>
              </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Divider/>
        <Accordion>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <span className='faqNumber'>03</span>
                <span className='faqQuestion'>Can I make videos for this app?</span>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                    If you are passionate about helping the next generation of professionals navigate their 
                    careers and have the experience and expertise to make a meaningful impact, we encourage 
                    you to become a creator on Portal today. As a creator on Portal, you will have the opportunity 
                    to share your knowledge and experiences with a diverse, motivated audience of students and young 
                    professionals. You will also have access to exclusive networking opportunities, future industry events, 
                    and potentially financial compensation to help you grow your own career and expand your impact.
                    Click "Contact Us" now to begin the process!
                </p>
              </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Divider/>
        <Accordion>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <span className='faqNumber'>04</span>
                <span className='faqQuestion'>Is Portal available on Android?</span>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                    Yes, Portal is available on Android and iOS.
                </p>
              </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Divider/>
        <Accordion>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <span className='faqNumber'>05</span>
                <span className='faqQuestion'>When will Portal be launched officially?</span>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                    The official launch of Portal will take place in early September. However, our 
                    Beta version will be available throughout the summer beginning in July. If you 
                    would like to get early access to the application, click the "Get Early Access" button.
                </p>
              </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Divider/>
      </Stack>
      <Stack className='footer' style={{marginLeft:25, marginRight:25}}>
        <img 
          src={logo} 
          alt='logo' 
          width='240px'
          style={{paddingTop:'40px', paddingBottom:'20px'}}
        />
        <h3>CONTENTS</h3>
        <button onClick={() => {
          wwdRef.current.scrollIntoView({behavior: 'smooth'})
        }}>What We Do</button>
        <button onClick={() => {
          featRef.current.scrollIntoView({behavior: 'smooth'})}
        }>Features</button>
        <button onClick={() => {
          ccRef.current.scrollIntoView({behavior: 'smooth'})}
        }>Career Centers</button>
        <button onClick={() => {
          faqRef.current.scrollIntoView({behavior: 'smooth'})}
        }>FAQ</button>
        <button onClick={handleShowContactForm}>Contact Us</button>
        <button onClick={handleShowEarlyAccess} style={{fontWeight:800}}>Get Early Access</button>
        <Divider/>
        <p>© 2023. All Rights Reserved. </p>
      </Stack>
    </div>
  );
}

export default App;
